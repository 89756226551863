import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="import-goods-stock"
export default class extends Controller {
  connect() {
    this.initialDataWarning();
  }

  initialDataWarning() {
    const element = document.getElementById('import-warning');
    const warning = JSON.parse(element.getAttribute('data-warning'))

    if (!warning) { return }

    const hasError = warning.error;
    const hasInvalidLines = warning.invalid_line.length > 0;
    const hasNotFound = warning.not_found.length > 0;

    if (hasError || hasInvalidLines || hasNotFound) {
      this.myModal($('#template-error')).show();
    }
  }

  submitForm() {
    document.getElementById('missing-goods-stock-form')?.submit();
  }

  myModal(element) {
    return new bootstrap.Modal(element, { keyboard: false });
  }
}
